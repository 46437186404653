import React from 'react';

const Spinner = () => {

  return (
    <svg
      className="animate-spin h-5 w-5"
      viewBox="0 0 24 24"
    />
  );
};

export default Spinner;
import React from "react"
import { WalletContext } from "../contexts/WalletContext"

export function useWeb3Wallet() {
  const context = React.useContext(WalletContext);

  if (context === undefined) {
    throw new Error('useWeb3Wallet hook must be used with a WalletProvider component')
  }

  return context;
};